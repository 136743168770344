.margin-top-10{
    margin-top:10px;
}

.margin-left-10{
    margin-left:10px;
}

.font-ergo{
	font-family: "Computer Aid";
}

.img-lueur{
	/*filter : drop-shadow(0px -6mm 4mm #FFFFFF);*/
	filter : drop-shadow(-6mm 6mm 3mm rgb(0, 0, 0, 1));
}

.main-container{
	background-color:#292a2b;
	padding-top:150px;
	padding-bottom:50px;
}


.btn-get-ergone{
	background-color:#1b4a50;
	border-radius:25px;
	padding-left:35px;
	padding-right:35px;
}

.btn-get-ergone:hover{
	background-color:#2ac2aa!important;
	/*padding-right:25px;*/
}

.btn-whitepaper{
	border-radius:25px;
	padding-left:35px;
	padding-right:35px;
}

.btn-whitepaper:hover{
	color:black!important;
	background-color:white!important;
}

.font-size-title{
	font-size:80px;
}

.font-size-subtitle{
	font-size:28px;
	font-weight: bold;
}

.font-size-subtitled{
	font-size:22px;
}

.font-style-cap{
	text-transform: uppercase;
}

.img-alloc{
	margin-bottom:25px;
}

.subtitle-alloc{
	font-size:20px;
	margin-bottom:10px;
	font-weight: bold;
}

.img-resisez{
	width:550px;
}

.title-alloc{
	margin-top:70px;
	font-style:italic;
}

.padding-bt{
    padding-top:50px;
    padding-bottom:50px;
}

.margin-b-50{
    margin-bottom:50px;
}

.title-roadmap{
    margin-bottom:100px;
    font-size:45px;
}

.community-bloc{
    background-color: rgba(0, 0, 0, 0.2);
}

.margin-left-15{
    margin-left:15px;
}

.sub-title-page{
	font-family: "Deltaray";
	font-size:8vw;
	margin-top:70px;
	margin-bottom:150px!important;
}

.sub-title-page-nft{
	font-family: "Deltaray";
	font-size:8vw;
	margin-top:70px;
}

.subtitle-nft{
    margin-bottom:200px;
    margin-top:25px;
    font-style:italic;
}

.img-thumbnail{
	width: 450px!important;
}

.nft-desc{
	margin-bottom:170px;
}

.nft-link{
	margin-top:25px;
}

.disabled{
	color:#707070
}

.img-center{
	align-items: center;
	display:flex; 
	flex-wrap: wrap;
}

.ico-link{
	margin-left:25px;
}

/*  ROADMAP */


:root {
	--white: #ffffff;
	--black: #000000;
	--blue:#0288d1;
	--gray:#ebebeb;
	--box-shadow1:0px 0px 18px 2px rgba(10, 55, 90, 0.15);
}
body{
	font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    color: #637280;
}
:focus{
    outline: 0px solid transparent !important;
}
.timeline {
    padding: 50px 0;
    position: relative;
}
.timeline-nodes {
        padding-bottom: 25px;
        position: relative;
    }
.timeline-nodes:nth-child(even) {
    flex-direction: row-reverse;
}
.timeline h3, .timeline p {
    padding: 5px 15px;
} 
.timeline h3{
    font-weight: lighter;
    background: #2ebfab;
    text-align: center;
}

.left-h3{
    padding-right: 45%!important;
    background: #ba0afa!important;
}

.timeline p, .timeline time {
    color: #2ebfab
}

.timeline-content-left p{
    color: #ba0afa!important;
}

.timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 2px dashed #2ebfab;
    height: 100%;
    z-index: 2;
    transform: translateX(-50%);
}
.timeline-content {
    border: 1px solid #2ebfab;
    position: relative;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 3px 25px 0px rgba(10, 55, 90, 0.2)
}

.timeline-content-left{
    border: 1px solid #ba0afa!important;
    color: #ba0afa!important;
}


.timeline-nodes:nth-child(odd) h3,
.timeline-nodes:nth-child(odd) p {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-date {
    text-align: left;
}
 
.timeline-nodes:nth-child(even) .timeline-date {
    text-align: right;
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    left: 100%;
    width: 0;
    border-left: 10px solid #ba0afa;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-nodes:nth-child(even) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    right: 100%;
    width: 0;
    border-right: 10px solid #2ebfab;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-image {
    position: relative;
    z-index: 4;
}
.timeline-image::before {
    content: "";
    width: 40px;
    height: 40px;
    border: 2px dashed #2ebfab;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	background-color: #2ebfab;
    z-index: 3;
    

}
.timeline-image img {
    position: relative;
    z-index: 100;
}
/*small device style*/

@media (max-width: 767px) {
    .timeline-nodes:nth-child(odd) h3,
    .timeline-nodes:nth-child(odd) p {
    text-align: left
}
.timeline-nodes:nth-child(even) {
    flex-direction: row;
}
    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 4%;
    width: 0;
    border-left: 2px dashed #2ebfab;
    height: 100%;
    z-index: 3;
    transform: translateX(-50%);
}
.timeline h3 {
    font-size: 1.7rem;
}
.timeline p {
    font-size: 14px;
}
.timeline-image {
    position: absolute;
    left: 0%;
    top: 60px;
    /*transform: translateX(-50%;);*/
}
.timeline-nodes:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    left: auto;
    right: 100%;
    width: 0;
    border-left: 0;
    border-right: 10px solid #2ebfab;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-nodes:nth-child(even) .timeline-content::after {
    content: "";
    position: absolute;
    top: 5%;
    right: 100%;
    width: 0;
    border-right: 10px solid #2ebfab;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
}
.timeline-image::before {
    width: 65px;
    height: 65px;
}
}

/*extra small device style */
@media (max-width: 575px) {
    .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 3%;
}
.timeline-image {
    position: absolute;
    left: -5%;
    }
.timeline-image::before {
    width: 60px;
    height: 60px;
}
}

.nft-font-size{
    font-size:28px!important;
}

.col-nft-desc{
    margin-bottom: 150px;
    text-align: left;
    margin-left:25px;
    font-size: 20px;
    font-style: italic;
}

.little-nft{
    font-size: 5vw;
}

.img-thumbnail{
    margin-top:75px!important;
}

.pocopweb{
    margin-top:150px;
    font-size:23px;
}

.pocopwebimage{
    width:100%;
    margin-bottom: 150px;
}

.pocoplink{
    color:white!important;
}

.pocoplink:hover{
    
    color:#2ac2aa!important;
    
}

.logo-partner{
    margin-top:3em;
}

.row-partners{
    margin-top:2.5em;
}

.ico-link:hover{
    text-decoration: none;
    color:#2ac2aa!important;
    
}

a:hover{
    color:#2ac2aa!important;
}