.container-rmc{
	margin-top:10%;
	color:white;
	width:40%;
	text-align:center;
	margin-left:3em;
}

.right-col{
	text-align: right;
}


.left-col{
	text-align: left;
}